import KatalMetricObject from '@katal/metrics/lib/metricObject/KatalMetricObject';
import KatalMetricsContext from '@katal/metrics/lib/KatalMetricsContext';
import KatalMetricsDriver from '@katal/metrics/lib/driver/KatalMetricsDriver';
import KatalLogger from '@katal/logger';

/**
 * Class to wrap Metrics Drivers that will log the metrics before publishing them via the wrapped Driver
 */
export class ArgoMetricsDriver extends KatalMetricsDriver {
    private metricsDriver: KatalMetricsDriver
    private katalLogger: KatalLogger

    /**
     * @param driver The driver to wrap the calls for
     * @param loggerUrl The logger endpoint to publish metrics to
     */
    constructor(driver: KatalMetricsDriver, loggerUrl: string) {
        super();

        this.metricsDriver = driver;

        this.katalLogger = new KatalLogger({
            url: loggerUrl,
            logToConsole: false
        });
    }

    /**
     * This overrides the publish method of the KatalMetricsDriver interface. It will push the Katal metric object and
     *  context to the KataLogger endpoint, then also publish it through the passed in MetricsDriver
     *
     * @param metricObject The KataMetricObject that this publish event is for
     * @param context The KatalMetricsContext for this metric
     */
    publish(metricObject: KatalMetricObject,  context: KatalMetricsContext) {
        const logContext = {
            metricObject,
            metricContext: context,
            isMetric: true
        }

        this.katalLogger.info("Metric", logContext);

        // Publish the metric to the wrapped metric driver as well
        this.metricsDriver.publish(metricObject, context);
    }
}